.loadingSpinner {
  @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;

  .semiCircle {
    @apply w-10 h-10 relative;

    .border {
      @apply w-full h-full border-4 border-transparent border-t-primary rounded-full animate-spin;
    }

    .message {
      @apply absolute top-0 left-0 w-full h-full flex items-center justify-center text-lg font-bold;
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
